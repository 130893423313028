<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Employment Eligibility Verification
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form ref="formFieldsRef">

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-card-text
                        class="text-center"
                      >
                        <strong>Department of Homeland Security</strong><br>
                        U.S Citizenship and Immigration Services
                      </b-card-text>
                      <hr>

                      <b-card-text
                        class="mt-1"
                      >
                        <strong>START HERE: Read instructions carefully before completing this form. The instructions must be available, either in paper of electronically, during completion of this form. Employers are liable for errors in the completion of this form.</strong><br>
                        <strong>ANTI-DISCRIMINATION NOTICE:</strong> It is illegal to discriminate against work-authorized individuals. Employers <strong>CANNOT</strong> specify which document(s) an employee may present to establish employment authorization and identity. The refusal to hire or continue to employ an individual because the documentation presented has a future expiration date may also constitute illegal discrimination.<br><br>
                        <strong>Section 1.</strong> Employee Information and Attestation (Employees must complete and sign Section 1 of Form I-9 no later than the <strong>first day of employment</strong>, but not before accepting a job offer.)
                      </b-card-text>

                    </b-col>
                  </b-row>

                  <b-row
                    class="mt-2"
                  >
                    <b-col
                      cols="12"
                      lg="5"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="lastName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="5"
                    >

                      <b-form-group
                        label="* First Name"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            v-model="firstName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="middle-name"
                      >
                        <label
                          class="sr-only"
                          for="middle-name"
                        >
                          Middle Name
                        </label>
                        <b-form-input
                          id="middle-name"
                          v-model="middleName"
                          placeholder="Middle Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <!-- <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Other Last Names Used (if any)"
                        label-for="other-last-name"
                      >
                        <label
                          class="sr-only"
                          for="other-last-name"
                        >
                          * Other Last Names Used (if any)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Other Last Names Used"
                          rules="required"
                        >
                          <b-form-input
                            id="other-last-name"
                            v-model="otherLastName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Other Last Names Used"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col> -->

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Other Last Names Used (if any)"
                        label-for="other-last-name"
                      >
                        <label
                          class="sr-only"
                          for="other-last-name"
                        >
                          * Other Last Names Used (if any)
                        </label>
                        <b-form-input
                          id="other-last-name"
                          v-model="otherLastName"
                          placeholder="Other Last Names Used"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Address (Street Number and Name)"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Address (Street Number and Name)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            v-model="address"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="2"
                    >

                      <b-form-group
                        label="* Apt. Number"
                        label-for="apt-number"
                      >
                        <label
                          class="sr-only"
                          for="apt-number"
                        >
                          * Apt. Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Apt. Number"
                          rules="required"
                        >
                          <b-form-input
                            id="apt-number"
                            v-model="aptNumber"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Apt. Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="5"
                    >

                      <b-form-group
                        label="* City or Town"
                        label-for="city-town"
                      >
                        <label
                          class="sr-only"
                          for="city-town"
                        >
                          * City or Town
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City or Town"
                          rules="required"
                        >
                          <b-form-input
                            id="city-town"
                            v-model="cityTown"
                            :state="errors.length > 0 ? false : null"
                            placeholder="City or Town"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* State"
                        label-for="state"
                      >
                        <label
                          class="sr-only"
                          for="state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          rules="required"
                        >
                          <b-form-input
                            id="state"
                            v-model="state"
                            :state="errors.length > 0 ? false : null"
                            placeholder="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="2"
                    >

                      <b-form-group
                        label="* Zip Code"
                        label-for="zip-code"
                      >
                        <label
                          class="sr-only"
                          for="zip-code"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip Code"
                          rules="required"
                        >
                          <b-form-input
                            id="zip-code"
                            v-model="zipCode"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Birth"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Birth"
                          label-for="date-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-birth"
                          >
                            * Date of Birth
                          </label>
                          <flat-pickr
                            id="date-birth"
                            v-model="dateBirth"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* U.S. Social Security Number"
                        label-for="us-ssn"
                      >
                        <label
                          class="sr-only"
                          for="us-ssn"
                        >
                          * U.S. Social Security Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="U.S. Social Security Number"
                          rules="required"
                        >
                          <b-form-input
                            id="us-ssn"
                            v-model="usSsn"
                            v-mask="['###-##-####']"
                            :state="errors.length > 0 ? false : null"
                            placeholder="U.S. Social Security Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col> -->

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="U.S. Social Security Number"
                        label-for="us-ssn"
                      >
                        <label
                          class="sr-only"
                          for="us-ssn"
                        >
                          U.S. Social Security Number
                        </label>
                        <b-form-input
                          id="us-ssn"
                          v-model="usSsn"
                          v-mask="['###-##-####']"
                          placeholder="U.S. Social Security Number"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Employee's E-mail Address"
                        label-for="emp-email-address"
                      >
                        <label
                          class="sr-only"
                          for="emp-email-address"
                        >
                          * Employee's E-mail Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee's E-mail Address"
                          rules="required|email"
                        >
                          <b-form-input
                            id="emp-email-address"
                            v-model="empEmailAddress"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            placeholder="Employee's E-mail Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Employee's Telephone Number"
                        label-for="emp-telephone"
                      >
                        <label
                          class="sr-only"
                          for="emp-telephone"
                        >
                          * Employee's Telephone Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee's Telephone Number"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="emp-telephone"
                              v-model="empTelephone"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="1234 567 8900"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-card-text
                        class="mt-1 mb-1"
                      >
                        <strong>I am aware that federal law provides for imprisonment and/or fines for false statements or use of false documents in connection with the completion of this form.<br>I attest, under penalty of perjury, that I am (check one of the following boxes):</strong>
                      </b-card-text>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Citizenship Status"
                        rules="required"
                      >
                        <b-form-radio
                          v-for="option in firstOptions"
                          ref="oath"
                          :key="option.id"
                          v-model="form.oath"
                          :value="option.text"
                          name="checkbox3"
                          :state="errors.length > 0 ? false : null"
                          title="Citizenship Status"
                        >
                          {{ option.text }}
                          <div
                            v-if="option.id === 3"
                          >
                            <b-form-input
                              id="alien-reg-num"
                              ref="alienRegNum"
                              v-model="form.alienRegNum"
                              class="w-300"
                              placeholder="Alien Registration Number/USCIS Number (specify)"
                              title="Alien Registration Number/USCIS Number"
                            />
                          </div>
                          <div
                            v-else-if="option.id === 4"
                          >
                            <b-input-group class="flatpickr">
                              <flat-pickr
                                id="alien-exp-date"
                                ref="alienExpDate"
                                v-model="form.alienExpDate"
                                class="form-control"
                                :config="flatPickrConfig"
                                placeholder="Expiration Date"
                                title="Alien Expiration Date"
                              />
                              <b-input-group-append>
                                <b-button
                                  variant="outline-dark"
                                  title="Clear"
                                  class="brdr-gray px-1"
                                  data-clear
                                >
                                  <i class="fa fa-times">
                                    <span
                                      aria-hidden="true"
                                      class="sr-only"
                                    >
                                      Clear
                                    </span>
                                  </i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <b-card-text>
                              Some aliens may write "N/A" in the expiration date field. (See instructions)<br><br>
                              <em>Aliens authorized to work must provide only one of the following document numbers to complete Form I-9:<br>
                                An Alien Registration Number/USCIS Number OR Form I-94 Admission Number OR Foreign Passport Number.</em>
                            </b-card-text>

                            <b-form-group
                              label="1. Alien Registration Number/USCIS Number"
                              label-for="alien-reg-uscis-num"
                            >
                              <label
                                class="sr-only"
                                for="alien-reg-uscis-num"
                              >
                                1. Alien Registration Number/USCIS Number
                              </label>
                              <b-form-input
                                id="alien-reg-uscis-num"
                                ref="alienRegUscisNum"
                                v-model="form.alienRegUscisNum"
                                placeholder="Alien Registration Number/USCIS Number"
                                title="Alien Registration Number/USCIS Number"
                              />
                            </b-form-group>

                            <b-form-group
                              label="2. Form I-94 Admission Number"
                              label-for="i-94-adm-num"
                            >
                              <label
                                class="sr-only"
                                for="i-94-adm-num"
                              >
                                2. Form I-94 Admission Number
                              </label>
                              <b-form-input
                                id="i-94-adm-num"
                                ref="i94AdmNum"
                                v-model="form.i94AdmNum"
                                placeholder="Form I-94 Admission Number"
                                title="Form I-94 Admission Number"
                              />
                            </b-form-group>

                            <b-form-group
                              label="3. Foreign Passport Number"
                              label-for="foreign-passport-num"
                            >
                              <label
                                class="sr-only"
                                for="foreign-passport-num"
                              >
                                3. Foreign Passport Number
                              </label>
                              <b-form-input
                                id="foreign-passport-num"
                                ref="foreignPassportNum"
                                v-model="form.foreignPassportNum"
                                placeholder="Foreign Passport Number"
                                title="Foreign Passport Number"
                              />
                            </b-form-group>

                            <b-form-group
                              label="Country of Issuance"
                              label-for="country-issuance"
                            >
                              <label
                                class="sr-only"
                                for="country-issuance"
                              >
                                Country of Issuance
                              </label>
                              <b-form-input
                                id="country-issuance"
                                ref="countryIssuance"
                                v-model="form.countryIssuance"
                                placeholder="Country of Issuance"
                                title="Country of Issuance"
                              />
                            </b-form-group>
                          </div>
                        </b-form-radio>
                        <small class="text-danger"><br>{{ errors[0] }}</small>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <b-form-group
                        label="QR Code - Section 1"
                        label-for="qrCode-sec1"
                      >
                        <label
                          class="sr-only"
                          for="qrCode-sec1"
                        >
                          QR Code - Section 1 {{}}
                        </label>
                        <b-form-file
                          ref="qrCodeSec1"
                          v-model="form.qrCodeSec1"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          type="file"
                          accept="image/*"
                          capture
                          title="QR Code - Section 1"
                        />
                      </b-form-group>

                      <b-list-group v-if="qrCodeSec1File">

                        <b-list-group-item
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${qrCodeSec1File}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ qrCodeSec1File }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-qrcode-sec2n3-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: qrCodeSec1File })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-qrcode-sec2n3-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-qrcode-sec2n3-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, qrCodeSec1: qrCodeSec1File })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-qrcode-sec2n3-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Today's Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Today's Date"
                          label-for="today-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="today-date"
                          >
                            * Today's Date
                          </label>
                          <flat-pickr
                            id="today-date"
                            ref="todayDate"
                            v-model="form.todayDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Today's Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Signature of Employee"
                        label-for="pt-signature"
                      >
                        <label
                          class="sr-only"
                          for="pt-signature"
                        >
                          * Signature of Employee
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature of Employee"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="ptSignature"
                            v-model="form.ptSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.ptSignature === '' }"
                            title="Signature of Employee"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.ptSignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignaturePT"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignaturePT"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Preparer and/or Translator Certification (check one):"
                        label-for="preparer-translator"
                      >
                        <label
                          class="sr-only"
                          for="preparer-translator"
                        >
                          * Preparer and/or Translator Certification (check one):
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Preparer and Translator Certification"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="preparerTranslator"
                            v-model="form.preparerTranslator"
                            :options="['I did not use a preparer or translator.', 'A preparer(s) and/or translator(s) assisted the employee in completing Section 1.']"
                            :state="errors.length > 0 ? false : null"
                            title="Preparer and/or Translator Certification"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-card-text
                        class="mt-1"
                      >
                        <em>(Fields below must be completed and signed when preparers and/or translators assist an employee in completing Section 1.)</em><br><strong>I attest, under penalty of perjury, that I have assisted in the completion of Section 1 of this form and that to the best of my knowledge the information is true and correct.</strong>
                      </b-card-text>

                    </b-col>
                  </b-row>

                  <b-row
                    class="mt-1"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="Last Name"
                        label-for="pt-last-name"
                      >
                        <label
                          class="sr-only"
                          for="pt-last-name"
                        >
                          Last Name
                        </label>
                        <b-form-input
                          id="pt-last-name"
                          ref="ptLastName"
                          v-model="form.ptLastName"
                          placeholder="Last Name"
                          title="Last Name"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="First Name"
                        label-for="pt-first-name"
                      >
                        <label
                          class="sr-only"
                          for="pt-first-name"
                        >
                          First Name
                        </label>
                        <b-form-input
                          id="pt-first-name"
                          ref="ptFirstName"
                          v-model="form.ptFirstName"
                          placeholder="First Name"
                          title="First Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="Address (Street Number and Name)"
                        label-for="pt-address"
                      >
                        <label
                          class="sr-only"
                          for="pt-address"
                        >
                          Address (Street Number and Name)
                        </label>
                        <b-form-input
                          id="pt-address"
                          ref="ptAddress"
                          v-model="form.ptAddress"
                          placeholder="Address (Street Number and Name)"
                          title="Address"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="City or Town"
                        label-for="pt-city-town"
                      >
                        <label
                          class="sr-only"
                          for="pt-city-town"
                        >
                          City or Town
                        </label>
                        <b-form-input
                          id="pt-city-town"
                          ref="ptCityTown"
                          v-model="form.ptCityTown"
                          placeholder="City or Town"
                          title="City or Town"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* State"
                        label-for="state"
                      >
                        <v-select
                          ref="state"
                          v-model="form.ptState"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="stateOptions"
                          taggable
                          push-tags
                          placeholder="Select or Type State"
                          title="State"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="Zip Code"
                        label-for="pt-zip-code"
                      >
                        <label
                          class="sr-only"
                          for="pt-zip-code"
                        >
                          Zip Code
                        </label>
                        <b-form-input
                          id="pt-zip-code"
                          ref="ptZipCode"
                          v-model="form.ptZipCode"
                          placeholder="Zip Code"
                          title="Zip Code"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <hr>
                  <p
                    class="text-center"
                  >
                    <b><em>Employer Completes Next Fields</em></b>
                  </p>

                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-card-text
                        class="mt-1"
                      >
                        <strong>Section 2.</strong> Employer or Authorized Representative Review and Verification<br>
                        <em>(Employers or their authorized representative must complete and sign Section 2 within 3 business days of the employee's first day of employment. You must physically examine one document from List A OR a combination of one document from List B and one document from List C as listed on the "Lists of Acceptable Documents")</em>
                      </b-card-text>

                    </b-col>
                  </b-row>

                  <b-card-text
                    class="mt-1"
                  >
                    Employee Info from Section 1
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="sec1-last-name"
                      >
                        <label
                          class="sr-only"
                          for="sec1-last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Section 1 Last Name"
                          rules="required"
                        >
                          <b-form-input
                            id="sec1-last-name"
                            v-model="sec1LastName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* First Name"
                        label-for="sec1-first-name"
                      >
                        <label
                          class="sr-only"
                          for="sec-1first-name"
                        >
                          * First Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Section 1 First Name"
                          rules="required"
                        >
                          <b-form-input
                            id="sec1-first-name"
                            v-model="sec1FirstName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="sec1-middle-initial"
                      >
                        <label
                          class="sr-only"
                          for="sec1-middle-initial"
                        >
                          Middle Name
                        </label>
                        <b-form-input
                          id="sec1-middle-initial"
                          v-model="sec1MiddleName"
                          placeholder="Middle Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Citizenship/Immigration Status"
                        label-for="sec1-ct-status"
                      >
                        <label
                          class="sr-only"
                          for="sec1-ct-status"
                        >
                          * Citizenship/Immigration Status
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Section 1 Citizenship/Immigration Status"
                          rules="required"
                        >
                          <b-form-input
                            id="sec1-ct-status"
                            v-model="sec1CTStatus"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Citizenship/Immigration Status"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-card-text
                    class="mt-1 text-center"
                  >
                    List A<br>
                    Identity and Employment Authorization
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <!-- List A 1 START -->
                      <b-form-group
                        label="Document Title"
                        label-for="listA1-doc-title"
                      >
                        <label
                          class="sr-only"
                          for="listA1-doc-title"
                        >
                          Document Title
                        </label>
                        <b-form-input
                          id="listA1-doc-title"
                          ref="listA1DocTitle"
                          v-model="form.listA1DocTitle"
                          placeholder="Document Title"
                          title="List A (1st) Document Title"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Issuing Authority"
                        label-for="listA1-issuing-authority"
                      >
                        <label
                          class="sr-only"
                          for="listA1-issuing-authority"
                        >
                          Issuing Authority
                        </label>
                        <b-form-input
                          id="listA1-issuing-authority"
                          ref="listA1IssuingAuthority"
                          v-model="form.listA1IssuingAuthority"
                          placeholder="Issuing Authority"
                          title="List A (1st) Issuing Authority"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Document Number"
                        label-for="listA1-document-number"
                      >
                        <label
                          class="sr-only"
                          for="listA1-document-number"
                        >
                          Document Number
                        </label>
                        <b-form-input
                          id="listA1-document-number"
                          ref="listA1DocumentNumber"
                          v-model="form.listA1DocumentNumber"
                          placeholder="Document Number"
                          title="List A (1st) Document Number"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Expiration Date (if any)"
                        label-for="listA1-exp-date"
                      >
                        <label
                          class="sr-only"
                          for="listA1-exp-date"
                        >
                          Expiration Date (if any)
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="listA1-exp-date"
                            ref="listA1ExpDate"
                            v-model="form.listA1ExpDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="List A (1st) Expiration Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <!-- List A 1 END -->

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <!-- List A 2 START -->
                      <b-form-group
                        label="Document Title"
                        label-for="listA2-doc-title"
                      >
                        <label
                          class="sr-only"
                          for="listA2-doc-title"
                        >
                          Document Title
                        </label>
                        <b-form-input
                          id="listA2-doc-title"
                          ref="listA2DocTitle"
                          v-model="form.listA2DocTitle"
                          placeholder="Document Title"
                          title="List A (2nd) Document Title"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Issuing Authority"
                        label-for="listA2-issuing-authority"
                      >
                        <label
                          class="sr-only"
                          for="listA2-issuing-authority"
                        >
                          Issuing Authority
                        </label>
                        <b-form-input
                          id="listA2-issuing-authority"
                          ref="listA2IssuingAuthority"
                          v-model="form.listA2IssuingAuthority"
                          placeholder="Issuing Authority"
                          title="List A (2nd) Issuing Authority"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Document Number"
                        label-for="listA2-document-number"
                      >
                        <label
                          class="sr-only"
                          for="listA2-document-number"
                        >
                          Document Number
                        </label>
                        <b-form-input
                          id="listA2-document-number"
                          ref="listA2DocumentNumber"
                          v-model="form.listA2DocumentNumber"
                          placeholder="Document Number"
                          title="List A (2nd) Document Number"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Expiration Date (if any)"
                        label-for="listA2-exp-date"
                      >
                        <label
                          class="sr-only"
                          for="listA2-exp-date"
                        >
                          Expiration Date (if any)
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="listA2-exp-date"
                            ref="listA2ExpDate"
                            v-model="form.listA2ExpDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="List A (2nd) Expiration Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <!-- List A 2 END -->

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <!-- List A 3 START -->
                      <b-form-group
                        label="Document Title"
                        label-for="listA3-doc-title"
                      >
                        <label
                          class="sr-only"
                          for="listA3-doc-title"
                        >
                          Document Title
                        </label>
                        <b-form-input
                          id="listA3-doc-title"
                          ref="listA3DocTitle"
                          v-model="form.listA3DocTitle"
                          placeholder="Document Title"
                          title="List A (3rd) Document Title"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Issuing Authority"
                        label-for="listA3-issuing-authority"
                      >
                        <label
                          class="sr-only"
                          for="listA3-issuing-authority"
                        >
                          Issuing Authority
                        </label>
                        <b-form-input
                          id="listA3-issuing-authority"
                          ref="listA3IssuingAuthority"
                          v-model="form.listA3IssuingAuthority"
                          placeholder="Issuing Authority"
                          title="List A (3rd) Issuing Authority"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Document Number"
                        label-for="listA3-document-number"
                      >
                        <label
                          class="sr-only"
                          for="listA3-document-number"
                        >
                          Document Number
                        </label>
                        <b-form-input
                          id="listA3-document-number"
                          ref="listA3DocumentNumber"
                          v-model="form.listA3DocumentNumber"
                          placeholder="Document Number"
                          title="List A (3rd) Document Number"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Expiration Date (if any)"
                        label-for="listA3-exp-date"
                      >
                        <label
                          class="sr-only"
                          for="listA3-exp-date"
                        >
                          Expiration Date (if any)
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="listA3-exp-date"
                            ref="listA3ExpDate"
                            v-model="form.listA3ExpDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="List A (3rd) Expiration Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <!-- List A 3 END -->

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <!-- List B 1 START -->
                      <b-card-text
                        class="mt-1 text-center"
                      >
                        List B<br>
                        Identity
                      </b-card-text>

                      <!-- List B 1 START -->
                      <b-form-group
                        label="Document Title"
                        label-for="listB1-doc-title"
                      >
                        <label
                          class="sr-only"
                          for="listB1-doc-title"
                        >
                          Document Title
                        </label>
                        <b-form-input
                          id="listB1-doc-title"
                          ref="listB1DocTitle"
                          v-model="form.listB1DocTitle"
                          placeholder="Document Title"
                          title="List B Document Title"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Issuing Authority"
                        label-for="listB1-issuing-authority"
                      >
                        <label
                          class="sr-only"
                          for="listB1-issuing-authority"
                        >
                          Issuing Authority
                        </label>
                        <b-form-input
                          id="listB1-issuing-authority"
                          ref="listB1IssuingAuthority"
                          v-model="form.listB1IssuingAuthority"
                          placeholder="Issuing Authority"
                          title="List B Issuing Authority"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Document Number"
                        label-for="listB1-document-number"
                      >
                        <label
                          class="sr-only"
                          for="listB1-document-number"
                        >
                          Document Number
                        </label>
                        <b-form-input
                          id="listB1-document-number"
                          ref="listB1DocumentNumber"
                          v-model="form.listB1DocumentNumber"
                          placeholder="Document Number"
                          title="List B Document Number"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Expiration Date (if any)"
                        label-for="listB1-exp-date"
                      >
                        <label
                          class="sr-only"
                          for="listB1-exp-date"
                        >
                          Expiration Date (if any)
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="listB1-exp-date"
                            ref="listB1ExpDate"
                            v-model="form.listB1ExpDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="List B Expiration Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <!-- List B 1 END -->

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <!-- List C 1 START -->
                      <b-card-text
                        class="mt-1 text-center"
                      >
                        List C<br>
                        Employment Authorization
                      </b-card-text>

                      <!-- List C 1 START -->
                      <b-form-group
                        label="Document Title"
                        label-for="listC1-doc-title"
                      >
                        <label
                          class="sr-only"
                          for="listC1-doc-title"
                        >
                          Document Title
                        </label>
                        <b-form-input
                          id="listC1-doc-title"
                          ref="listC1DocTitle"
                          v-model="form.listC1DocTitle"
                          placeholder="Document Title"
                          title="List C Document Title"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Issuing Authority"
                        label-for="listC1-issuing-authority"
                      >
                        <label
                          class="sr-only"
                          for="listC1-issuing-authority"
                        >
                          Issuing Authority
                        </label>
                        <b-form-input
                          id="listC1-issuing-authority"
                          ref="listC1IssuingAuthority"
                          v-model="form.listC1IssuingAuthority"
                          placeholder="Issuing Authority"
                          title="List C Issuing Authority"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Document Number"
                        label-for="listC1-document-number"
                      >
                        <label
                          class="sr-only"
                          for="listC1-document-number"
                        >
                          Document Number
                        </label>
                        <b-form-input
                          id="listC1-document-number"
                          ref="listC1DocumentNumber"
                          v-model="form.listC1DocumentNumber"
                          placeholder="Document Number"
                          title="List C Document Number"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Expiration Date (if any)"
                        label-for="listC1-exp-date"
                      >
                        <label
                          class="sr-only"
                          for="listC1-exp-date"
                        >
                          Expiration Date (if any)
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="listC1-exp-date"
                            ref="listC1ExpDate"
                            v-model="form.listC1ExpDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="List C Expiration Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                      <!-- List C 1 END -->

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <hr>
                      <b-form-group
                        label="Additional Information"
                        label-for="list-add-info"
                      >
                        <label
                          class="sr-only"
                          for="list-add-info"
                        >
                          Additional Information
                        </label>
                        <b-form-input
                          id="list-add-info"
                          ref="listAddInfo"
                          v-model="form.listAddInfo"
                          placeholder="Additional Information"
                          title="Additional Information"
                        />
                      </b-form-group>

                      <b-form-group
                        label="QR Code - Section 2 and 3"
                        label-for="qrCode-sec2-3"
                      >
                        <label
                          class="sr-only"
                          for="qrCode-sec2-3"
                        >
                          QR Code - Section 2 and 3
                        </label>
                        <b-form-file
                          ref="qrCodeSec2n3"
                          v-model="form.qrCodeSec2n3"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          type="file"
                          accept="image/*"
                          capture
                          title="QR Code - Section 2 and 3"
                        />
                      </b-form-group>

                      <b-list-group v-if="qrCodeSec2n3File">

                        <b-list-group-item
                          class="d-flex justify-content-between text-truncate"
                        >

                          <span class="d-inline-block text-truncate mr-1">
                            <b-link
                              :href="`${urlUpload}${id}/${qrCodeSec2n3File}`"
                              target="_blank"
                              class="font-weight-bold pointer-events-auto"
                            >
                              {{ qrCodeSec2n3File }}
                            </b-link>
                          </span>

                          <span class="d-inline-block">
                            <feather-icon
                              :id="`row-qrcode-sec2n3-preview-icon`"
                              icon="EyeIcon"
                              size="16"
                              class="mr-1 pointer-events-auto"
                              role="button"
                              @click="previewFormFile({ id: id, item: qrCodeSec2n3File })"
                            />
                            <b-tooltip
                              title="Preview"
                              :target="`row-qrcode-sec2n3-preview-icon`"
                            />

                            <feather-icon
                              :id="`row-qrcode-sec2n3-delete-icon`"
                              icon="TrashIcon"
                              size="16"
                              role="button"
                              @click="deleteFormFile({ id: id, qrCodeSec2n3: qrCodeSec2n3File })"
                            />
                            <b-tooltip
                              title="Delete"
                              :target="`row-qrcode-sec2n3-delete-icon`"
                            />
                          </span>
                        </b-list-group-item>
                      </b-list-group>

                    </b-col>
                  </b-row>

                  <b-card-text
                    class="mt-1"
                  >
                    <strong>Certification: I attest, under penalty of perjury, that (1) I have examined the document(s) presented by the above-named employee, (2) the above-listed document(s) appear to be genuine and to relate to the employee named, and (3) to the best of my knowledge the employee is authorized to work in the United States.</strong>
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="First Day of Employment"
                        rules="required"
                      >
                        <b-form-group
                          label="* The employee's first day of employment"
                          label-for="first-day-employment"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="first-day-employment"
                          >
                            * The employee's first day of employment
                          </label>
                          <flat-pickr
                            id="first-day-employment"
                            ref="firstDayEmployment"
                            v-model="form.firstDayEmployment"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="First Day of Employment"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Signature of Employer or Authorized Representative"
                        label-for="emp-rep-signature"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-signature"
                        >
                          * Signature of Employer or Authorized Representative
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Signature of Employer or Authorized Representative"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="empRepSignature"
                            v-model="form.empRepSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.empRepSignature === '' }"
                            title="Signature of Employer or Authorized Representative"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.empRepSignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignatureEmpRep"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignatureEmpRep"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Section 2 Today's Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Today's Date"
                          label-for="sec2-today-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="sec2-today-date"
                          >
                            * Today's Date
                          </label>
                          <flat-pickr
                            id="sec2-today-date"
                            ref="sec2TodayDate"
                            v-model="form.sec2TodayDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Section 2 Today's Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Title of Employer or Authorized Representative"
                        label-for="emp-rep-title"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-title"
                        >
                          * Title of Employer or Authorized Representative
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Title of Employer or Authorized Representative"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-title"
                            ref="empRepTitle"
                            v-model="form.empRepTitle"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Title of Employer or Authorized Representative"
                            title="Title of Employer or Authorized Representative"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Last Name of Employer or Authorized Representative"
                        label-for="emp-rep-last-name"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-last-name"
                        >
                          * Last Name of Employer or Authorized Representative
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name of Employer or Authorized Representative"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-last-name"
                            ref="empRepLastName"
                            v-model="form.empRepLastName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Last Name of Employer or Authorized Representative"
                            title="Last Name of Employer or Authorized Representative"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* First Name of Employer or Authorized Representative"
                        label-for="emp-rep-first-name"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-first-name"
                        >
                          * First Name of Employer or Authorized Representative
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name of Employer or Authorized Representative"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-first-name"
                            ref="empRepFirstName"
                            v-model="form.empRepFirstName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name of Employer or Authorized Representative"
                            title="First Name of Employer or Authorized Representative"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Employer's Business or Organization Name"
                        label-for="emp-rep-business"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-business"
                        >
                          * Employer's Business or Organization Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer's Business or Organization Name"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-business"
                            ref="empRepBusiness"
                            v-model="form.empRepBusiness"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Employer's Business or Organization Name"
                            title="Employer's Business or Organization Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Employer's Business or Organization Address (Street Number and Name)"
                        label-for="emp-rep-address"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-address"
                        >
                          * Employer's Business or Organization Address (Street Number and Name)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer's Business or Organization Address"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-address"
                            ref="empRepAddress"
                            v-model="form.empRepAddress"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Employer's Business or Organization Address (Street Number and Name)"
                            title="Employer's Business or Organization Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* City or Town"
                        label-for="emp-rep-city-town"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-city-town"
                        >
                          * City or Town
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer's City or Town"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-city-town"
                            ref="empRepCityTown"
                            v-model="form.empRepCityTown"
                            :state="errors.length > 0 ? false : null"
                            placeholder="City or Town"
                            title="Employer's City or Town"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* Employer's State"
                        label-for="emp-rep-state"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-state"
                        >
                          * Employer's State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer's State"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-state"
                            ref="empRepState"
                            v-model="form.empRepState"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Employer's State"
                            title="Employer's State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="3"
                    >

                      <b-form-group
                        label="* Zip Code"
                        label-for="emp-rep-zip-code"
                      >
                        <label
                          class="sr-only"
                          for="emp-rep-zip-code"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer's Zip Code"
                          rules="required"
                        >
                          <b-form-input
                            id="emp-rep-zip-code"
                            ref="empRepZipCode"
                            v-model="form.empRepZipCode"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Zip Code"
                            title="Employer's Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-card-text
                    class="mt-1"
                  >
                    <strong>Section 3.</strong> Reverification and Rehires (To be completed and signed by employer or authorized representative.)
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-card-text
                        class="mt-1"
                      >
                        <strong>A.</strong> New Name (if applicable)
                      </b-card-text>

                      <b-row>
                        <b-col
                          cols="12"
                          lg="4"
                        >

                          <b-form-group
                            label="Last Name"
                            label-for="sec3-last-name"
                          >
                            <label
                              class="sr-only"
                              for="sec3-last-name"
                            >
                              Last Name
                            </label>
                            <b-form-input
                              id="sec3-last-name"
                              v-model="sec3LastName"
                              placeholder="Last Name"
                            />
                          </b-form-group>

                        </b-col>

                        <b-col
                          cols="12"
                          lg="4"
                        >

                          <b-form-group
                            label="First Name"
                            label-for="sec3-first-name"
                          >
                            <label
                              class="sr-only"
                              for="sec3-first-name"
                            >
                              First Name
                            </label>
                            <b-form-input
                              id="sec3-first-name"
                              v-model="sec3FirstName"
                              placeholder="First Name"
                            />
                          </b-form-group>

                        </b-col>

                        <b-col
                          cols="12"
                          lg="4"
                        >

                          <b-form-group
                            label="Middle Name"
                            label-for="sec3-middle-initial"
                          >
                            <label
                              class="sr-only"
                              for="sec3-middle-initial"
                            >
                              Middle Name
                            </label>
                            <b-form-input
                              id="sec3-middle-initial"
                              v-model="sec3MiddleName"
                              placeholder="Middle Name"
                            />
                          </b-form-group>

                        </b-col>
                      </b-row>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-card-text
                        class="mt-1"
                      >
                        <strong>B.</strong> Date of Rehire (if applicable)
                      </b-card-text>

                      <b-form-group
                        label="Date"
                        label-for="sec3-date-rehire"
                      >
                        <label
                          class="sr-only"
                          for="sec3-date-rehire"
                        >
                          Date
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="sec3-date-rehire"
                            ref="sec3DateRehire"
                            v-model="form.sec3DateRehire"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date of Rehire"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-card-text
                    class="mt-1"
                  >
                    <strong>C.</strong> If the employee's previous grant of employment authorization has expired, provide the information for the document or receipt that establishes continuing employment authorization in the space provided below.
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Document Title"
                        label-for="sec3-doc-title"
                      >
                        <label
                          class="sr-only"
                          for="sec3-doc-title"
                        >
                          * Document Title
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Section 3 Document Title"
                          rules="required"
                        >
                          <b-form-input
                            id="sec3-doc-title"
                            ref="sec3DocTitle"
                            v-model="form.sec3DocTitle"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Document Title"
                            title="Section 3 C. Document Title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Document Number"
                        label-for="sec3-document-number"
                      >
                        <label
                          class="sr-only"
                          for="sec3-document-number"
                        >
                          * Document Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Section 3 Document Number"
                          rules="required"
                        >
                          <b-form-input
                            id="sec3-document-number"
                            ref="sec3DocumentNumber"
                            v-model="form.sec3DocumentNumber"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Document Number"
                            title="Section 3 C. Document Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Section 3 Expiration Date (if any)"
                        rules="required"
                      >
                        <b-form-group
                          label="* Expiration Date (if any)"
                          label-for="sec3-exp-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="sec3-exp-date"
                          >
                            * Expiration Date (if any)
                          </label>
                          <flat-pickr
                            id="sec3-exp-date"
                            ref="sec3ExpDate"
                            v-model="form.sec3ExpDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Section 3 C. Expiration Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col> -->

                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Expiration Date (if any)"
                        label-for="sec3-exp-date"
                      >
                        <label
                          class="sr-only"
                          for="sec3-exp-date"
                        >
                          Expiration Date (if any)
                        </label>
                        <flat-pickr
                          id="sec3-exp-date"
                          ref="sec3ExpDate"
                          v-model="form.sec3ExpDate"
                          class="form-control"
                          :config="flatPickrConfig"
                          placeholder="Choose a date"
                          title="Section 3 C. Expiration Date"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-card-text
                    class="mt-1"
                  >
                    <strong>I attest, under penalty of perjury, that to the best of my knowledge, this employee is authorized to work in the United States, and if the employee presented document(s) I have examined appear to be genuine and to relate to the individual.</strong>
                  </b-card-text>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Section 3 Signature of Employer or Authorized Representative"
                        label-for="sec3-emp-rep-signature"
                      >
                        <label
                          class="sr-only"
                          for="sec3-emp-repsignature"
                        >
                          * Section 3 Signature of Employer or Authorized Representative
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Section 3 Signature of Employer or Authorized Representative"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="sec3EmpRepSignature"
                            v-model="form.sec3EmpRepSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.sec3EmpRepSignature === '' }"
                            title="Section 3 Signature of Employer or Authorized Representative"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.sec3EmpRepSignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignatureSec3EmpRep"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignatureSec3EmpRep"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Section 3 Today's Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Today's Date"
                          label-for="sec3-today-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="sec3-today-date"
                          >
                            * Today's Date
                          </label>
                          <flat-pickr
                            id="sec3-today-date"
                            ref="sec3TodayDate"
                            v-model="form.sec3TodayDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Section 3 Today's Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Name of Employer or Authorized Representative"
                        label-for="sec3-emp-rep-name"
                      >
                        <label
                          class="sr-only"
                          for="sec3-emp-rep-name"
                        >
                          * Name of Employer or Authorized Representative
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Section 3 Name of Employer or Authorized Representative"
                          rules="required"
                        >
                          <b-form-input
                            id="sec3-emp-rep-name"
                            ref="sec3EmpRepName"
                            v-model="form.sec3EmpRepName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Name of Employer or Authorized Representative"
                            title="Name of Employer or Authorized Representative"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <hr>
                  <h4
                    class="text-center"
                  >
                    LIST OF ACCEPTABLE DOCUMENTS<br>All documents must be UNEXPIRED
                  </h4>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-card-text
                        class="mt-1 text-center"
                      >
                        Employees may present one selection from list A<br>
                        or a combination of one selection from List  B and one selection from List C.
                      </b-card-text>

                      <table>
                        <tbody>
                          <tr>
                            <th>LIST A<br>Documents that Establish Both Identity and Employment Authorization</th>
                            <th>LIST B<br>Documents that Establish Identity</th>
                            <th>LIST C<br>Documents that Establish Employment Authorization</th>
                          </tr>
                          <tr>
                            <td>
                              <strong>1.</strong> U.S. Passport or U.S. Passport Card
                              <br><strong>2.</strong> Permanent Resident Card or Alien Registration Receipt Card (Form I-551)
                              <br><strong>3.</strong> Foreign passport that contains a temporary I-551 stamp or temporary I-551 printed notation on a machine-readable immigrant visa.
                              <br><strong>4.</strong> Employment Authorization Document that contains a photograph (From I-766)
                              <br><strong>5.</strong> For a nonimmigrant alien authorized to work for a specific employer because of his or her status:
                              <br><span><strong>a.</strong> Foreign passport; and</span>
                              <br><span><strong>b.</strong> Form I-94 or Form I-94A that has the following:</span>
                              <br><span>(1) The same name as the passport; and</span>
                              <br><span>(2) An endorsement of the alien's nonimmigrant status as long as that period of endorsement has not yet expired and the proposed employment is not in conflict with any restrictions or limitations identified on the form.</span>
                              <br><strong>6.</strong> Passport from the Federated States of Micronesia (FSM) or the Republic of the Marshall Islands (RMI) with Form I-94 or Form I-94A indicating nonimmigrant admission under the Compact of Free Association Between the United States and the FSM or RMI
                            </td>
                            <td>
                              <strong>1.</strong> Driver's license or ID card issued by a State or outlying possession of the United States provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address.
                              <br><strong>2.</strong> ID card issued by federal, state or local government agencies or entities, provided it contains a photograph or information such as name, date of birth, gender, height, eye color, and address.
                              <br><strong>3.</strong> School ID card with a photograph
                              <br><strong>4.</strong> Voter's registration card
                              <br><strong>5.</strong> U.S. Military card or draft record
                              <br><strong>6.</strong> Military dependent's ID card
                              <br><strong>7.</strong> U.S. Coast Guard Merchant Mariner Card
                              <br><strong>8.</strong> Native American tribal document
                              <br><strong>9.</strong> Driver's license issued by a Canadian government authority
                              <br><strong>For persons under age 18 who are unable to present a document listed above:</strong>
                              <br><strong>10.</strong> School record or report card
                              <br><strong>11.</strong> Clinic, doctor, or hospital record
                              <br><strong>12.</strong> Day-care or nursery school record
                            </td>
                            <td>
                              <strong>1.</strong> A Social Security Account Number card, unless the card includes one of the following restrictions:
                              <br><span>(1) NOT VALID FOR EMPLOYMENT</span>
                              <br><span>(2) VALID FOR WORK ONLY WITH INS AUTHORIZATION</span>
                              <br><span>(3) VALID FOR WORK ONLY WITH DHS AUTHORIZATION</span>
                              <br><strong>2.</strong> Certification of report of birth issued by the Department of State (Forms DS-1350, FS-545, FS-240)
                              <br><strong>3.</strong> Original or certified copy of birth certificate issued by a State, county, municipal authority, or territory of the United States bearing an official seal
                              <br><strong>4.</strong> Native American tribal document
                              <br><strong>5.</strong> U.S. Citizen ID Card (Form I-197)
                              <br><strong>6.</strong> Identification Card for Use of Resident Citizen in the United States (Form I-179)
                              <br><strong>7.</strong> Employment authorization document issued by the Department of Homeland Security
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <b-card-text
                        class="mt-1 text-center"
                      >
                        Examples of many of these documents appear in the Handbook for Employers (M-274).<br>
                        Refer to the instructions for more information about acceptable receipts.
                      </b-card-text>

                    </b-col>
                  </b-row>

                  <b-button
                    variant="primary"
                    type="submit"
                    class="d-none"
                    @click.prevent="validationForm"
                  >
                    Test Btn
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form4', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form6', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormFile, BListGroup, BListGroupItem, BLink, BTooltip, BButton, BFormRadio, BFormRadioGroup, VBToggle,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import { mask } from 'vue-the-mask'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { mapState } from 'vuex'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormFile,
    BButton,
    BFormRadio,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BLink,
    BTooltip,

    vSelect,
    flatPickr,
    VueSignaturePad,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const ptSignature = ref(null)
    const empRepSignature = ref(null)
    const sec3EmpRepSignature = ref(null)

    const lastName = ref('')
    const firstName = ref('')
    const middleName = ref('')
    const otherLastName = ref('')
    const address = ref('')
    const aptNumber = ref('')
    const cityTown = ref('')
    const state = ref('')
    const zipCode = ref('')
    const dateBirth = ref('')
    const usSsn = ref('')
    const empEmailAddress = ref('')
    const empTelephone = ref('')
    const sec1LastName = ref('')
    const sec1FirstName = ref('')
    const sec1MiddleName = ref('')
    const sec1CTStatus = ref('')
    const sec3LastName = ref('')
    const sec3FirstName = ref('')
    const sec3MiddleName = ref('')

    // QR CODE
    const qrCodeSec1File = ref(null)
    const qrCodeSec2n3File = ref(null)
    /* const tmpQrCodeSec1 = ref('')
    const tmpQrCodeSec2n3 = ref('') */

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          lastName.value = jsonObjectLiterals.data.lastName
          firstName.value = jsonObjectLiterals.data.firstName
          middleName.value = jsonObjectLiterals.data.middleName
          otherLastName.value = jsonObjectLiterals.data.akaLastName
          address.value = jsonObjectLiterals.data.street
          aptNumber.value = jsonObjectLiterals.data.aptunit
          cityTown.value = jsonObjectLiterals.data.cityTown
          state.value = jsonObjectLiterals.data.state
          zipCode.value = jsonObjectLiterals.data.zipCode
          dateBirth.value = jsonObjectLiterals.data.dateOfBirth
          usSsn.value = jsonObjectLiterals.data.ssn
          empEmailAddress.value = jsonObjectLiterals.data.emailAddress
          empTelephone.value = jsonObjectLiterals.data.phoneNumber
          sec1LastName.value = jsonObjectLiterals.data.lastName
          sec1FirstName.value = jsonObjectLiterals.data.firstName
          sec1MiddleName.value = jsonObjectLiterals.data.middleName
          sec3LastName.value = jsonObjectLiterals.data.lastName
          sec3FirstName.value = jsonObjectLiterals.data.firstName
          sec3MiddleName.value = jsonObjectLiterals.data.middleName

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm5', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                sec1CTStatus.value = jsonObjectLiterals.data.oath

                if (jsonObjectLiterals.data.ptSignature) {
                  ptSignature.value.fromDataURL(jsonObjectLiterals.data.ptSignature)
                }
                if (jsonObjectLiterals.data.empRepSignature) {
                  empRepSignature.value.fromDataURL(jsonObjectLiterals.data.empRepSignature)
                }
                if (jsonObjectLiterals.data.sec3EmpRepSignature) {
                  sec3EmpRepSignature.value.fromDataURL(jsonObjectLiterals.data.sec3EmpRepSignature)
                }
                qrCodeSec1File.value = jsonObjectLiterals.data.qrCodeSec1
                qrCodeSec2n3File.value = jsonObjectLiterals.data.qrCodeSec2n3
                /* tmpQrCodeSec1.value = jsonObjectLiterals.data.qrCodeSec1
                tmpQrCodeSec2n3.value = jsonObjectLiterals.data.qrCodeSec2n3 */

                if (form.value.id) {
                  form.value.form = 'folder3_form5'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const stateOptions = [
      'AK',
      'AL',
      'AR',
      'AS',
      'AZ',
      'CA',
      'CO',
      'CT',
      'DC',
      'DE',
      'FL',
      'GA',
      'GU',
      'HI',
      'IA',
      'ID',
      'IL',
      'IN',
      'KS',
      'KY',
      'LA',
      'MA',
      'MD',
      'ME',
      'MI',
      'MN',
      'MO',
      'MS',
      'MT',
      'NC',
      'ND',
      'NE',
      'NH',
      'NJ',
      'NM',
      'NV',
      'NY',
      'OH',
      'OK',
      'OR',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VA',
      'VI',
      'VT',
      'WA',
      'WI',
      'WV',
      'WY',
    ]

    const flatPickrConfig = {
      wrap: true,
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      ptSignature,
      empRepSignature,
      sec3EmpRepSignature,

      lastName,
      firstName,
      middleName,
      otherLastName,
      address,
      aptNumber,
      cityTown,
      state,
      zipCode,
      dateBirth,
      usSsn,
      empEmailAddress,
      empTelephone,
      sec1LastName,
      sec1FirstName,
      sec1MiddleName,
      sec1CTStatus,
      sec3LastName,
      sec3FirstName,
      sec3MiddleName,
      qrCodeSec1File,
      qrCodeSec2n3File,
      /* tmpQrCodeSec1,
      tmpQrCodeSec2n3, */

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,
      stateOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      // validation
      required,
      email,
      urlUpload: this.$urlUpload,
      // disabledID3: 1,
      // disabledID4: 1,

      // options
      firstOptions: [
        {
          id: 1,
          text: 'A citizen of the United States',
        },
        {
          id: 2,
          text: 'A noncitizen national of the United States',
        },
        {
          id: 3,
          text: 'A lawful permanent resident',
        },
        {
          id: 4,
          text: 'An alien authorized to work',
        },
      ],
    }
  },
  computed: {
    ...mapState('app-hr', ['uploadPercentage']),
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    clearSignaturePT() {
      this.$refs.ptSignature.clearSignature()
    },
    undoSignaturePT() {
      this.$refs.ptSignature.undoSignature()
    },
    clearSignatureEmpRep() {
      this.$refs.empRepSignature.clearSignature()
    },
    undoSignatureEmpRep() {
      this.$refs.empRepSignature.undoSignature()
    },
    clearSignatureSec3EmpRep() {
      this.$refs.sec3EmpRepSignature.clearSignature()
    },
    undoSignatureSec3EmpRep() {
      this.$refs.sec3EmpRepSignature.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      let object = { isEmpty: null, data: null }

      object = this.$refs.ptSignature.saveSignature()
      this.form.ptSignature = !object.isEmpty ? object.data : ''

      object = this.$refs.empRepSignature.saveSignature()
      this.form.empRepSignature = !object.isEmpty ? object.data : ''

      object = this.$refs.sec3EmpRepSignature.saveSignature()
      this.form.sec3EmpRepSignature = !object.isEmpty ? object.data : ''
    },
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.ptSignature) {
            self.$refs.ptSignature.clearSignature()
            self.$refs.ptSignature.fromDataURL(jsonObjectLiterals.data.ptSignature)
          }
          if (jsonObjectLiterals.data.empRepSignature) {
            self.$refs.empRepSignature.clearSignature()
            self.$refs.empRepSignature.fromDataURL(jsonObjectLiterals.data.empRepSignature)
          }
          if (jsonObjectLiterals.data.sec3EmpRepSignature) {
            self.$refs.sec3EmpRepSignature.clearSignature()
            self.$refs.sec3EmpRepSignature.fromDataURL(jsonObjectLiterals.data.sec3EmpRepSignature)
          }
          self.qrCodeSec1File = jsonObjectLiterals.data.qrCodeSec1
          self.qrCodeSec2n3File = jsonObjectLiterals.data.qrCodeSec2n3

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* let object = { isEmpty: null, data: null }

      object = this.$refs.ptSignature.saveSignature()
      this.form.ptSignature = !object.isEmpty ? object.data : ''

      object = this.$refs.empRepSignature.saveSignature()
      this.form.empRepSignature = !object.isEmpty ? object.data : ''

      object = this.$refs.sec3EmpRepSignature.saveSignature()
      this.form.sec3EmpRepSignature = !object.isEmpty ? object.data : '' */

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm5' : 'app-hr/updateForm5', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    previewFormFile(params) {
      Swal.fire({
        text: params.item,
        padding: '1em 3em',
        // eslint-disable-next-line
        imageUrl: this.$urlUpload + params.id + '/' + params.item,
        imageAlt: params.item,
        showConfirmButton: false,
        showCloseButton: true,
        returnFocus: false,
      })
    },
    deleteFormFile(params) {
      const self = this

      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        // eslint-disable-next-line
        text: 'Do you want to delete ' + Object.values(params)[1] + '?',
        confirmButtonText: 'Proceed',
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-secondary',
          cancelButton: 'btn btn-outline-dark mr-1',
        },
        buttonsStyling: false,
        returnFocus: false,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-hr/deleteForm5File', params)
            .then(response => {
              // eslint-disable-next-line
              const jsonObjectLiterals = response.data.response.data

              // console.log(jsonObjectLiterals)

              // update object file
              const key = Object.keys(params)[1]
              self.form[key] = jsonObjectLiterals.data[key]
              self[`${key}File`] = jsonObjectLiterals.data[key]

              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                // eslint-disable-next-line
                text: 'Deleted succesfully.',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch(error => {
              console.error(error)

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                showConfirmButton: false,
                timer: 1500,
              })
            })
        }
      })
    },
    // getOption(option) {
    //   if (option.id === 3) {
    //     this.disabledID3 = (this.disabledID3 + 1) % 2

    //     if (this.disabledID3 === 1) {
    //       this.form.alienRegNum = ''
    //     }
    //   } else if (option.id === 4) {
    //     this.disabledID4 = (this.disabledID4 + 1) % 2

    //     if (this.disabledID4 === 1) {
    //       this.form.alienExpDate = ''
    //     }
    //   }
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
  .custom-radioClass {
    margin-top: 6px;
  }

  /* table, th, td {
    border: 1px solid #6e6b7b;
  } */

  table th {
    text-align: center;
    font-weight: 700;
  }

  table th, table td {
    padding: 10px;
  }

  table td span {
    padding-left: 10px;
  }

  [dir] .flatpickr-input[readonly]:disabled {
    background-color: #efefef;
  }

  .w-300 {
    width: 300px;
  }
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
